export const columns = [
{
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '用户id',
    dataIndex: 'userId',
    key: 'userId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '省份id',
    dataIndex: 'provinceId',
    key: 'provinceId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '省份',
    dataIndex: 'province',
    key: 'province',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '城市id',
    dataIndex: 'cityId',
    key: 'cityId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '城市',
    dataIndex: 'city',
    key: 'city',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '区县id',
    dataIndex: 'countyId',
    key: 'countyId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '区县',
    dataIndex: 'county',
    key: 'county',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '详细地址',
    dataIndex: 'address',
    key: 'address',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '地址类型（现居地址，身份证 地址等）',
    dataIndex: 'addrType',
    key: 'addrType',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人id',
    dataIndex: 'createUserId',
    key: 'createUserId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改人',
    dataIndex: 'modifyUser',
    key: 'modifyUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改人id',
    dataIndex: 'modifyUserId',
    key: 'modifyUserId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否删除',
    dataIndex: 'flagDel',
    key: 'flagDel',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '版本号',
    dataIndex: 'version',
    key: 'version',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
